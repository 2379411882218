<template>
    <div class="team-blocks">
        <transition-group class="team-blocks__list" name="drop-fade" tag="ul">
            <li
                v-for="(person, i) in team"
                :key="`item-${i}`"
                class="team-blocks__list-item"
            >
                <div class="team-blocks__data-wrapper">
                    <h2 v-if="!small" v-html="person.name || person.title.rendered"/>
                    <h3 v-if="small" v-html="person.name || person.title.rendered"/>
                    <p v-if="person.position" v-html="person.position"/>
                    <router-link v-if="person.slug" :to="`${$route.path}/${person.slug}`">
                        {{getLang === 'de' ? 'Mehr erfahren' : 'Read more'}}
                    </router-link>
                </div>
                <div class="team-blocks__image-wrapper">
                    <img v-if="person.acf && person.acf.image" class="team-blocks__image" :alt="person.acf.image.alt" :src="person.acf.image.url"/>
                    <img v-if="person.image" class="team-blocks__image" :alt="person.image.alt" :src="person.image.url"/>
                </div>
            </li>
        </transition-group>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    name: 'TeamBlocks',
    props: {
        team: {
            type: Array,
            required: true
        },
        small: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        ...mapGetters([
            'getLang',
        ])
    }
}
</script>
<style lang="scss" scoped>
.team-blocks {
    &__list {
        margin: 0;
        padding: 0;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        grid-row-gap: 1.6rem;
        margin-bottom: 1.6rem;
        @media (min-width: 1024px) {
            grid-template-columns: repeat(auto-fill, minmax(300px, 50%));
            grid-row-gap: 130px;
            margin-bottom: 150px;
        }
    }
    &__list-item {
        margin: 0;
        padding: 0;
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: 320px;
        list-style-type: none;
    }
    &__data-wrapper, &__image-wrapper {
        display: flex;
        flex-direction: column
    }
    &__data-wrapper {
        justify-content: center;
        padding: 1.6rem;
        @media (min-width: 1024px) {
            padding: 65px 1.6rem 65px 65px;
        }
        h2 {
            color: #182952;
            font-size: 25px;
            line-height: 1.2;
            font-family: 'neue-plak';
            font-weight: 500;
            margin: 0;
            @media (min-width: 1024px) {
                font-size: 32px;
                letter-spacing: .1px;
                hyphens: auto;
            }
        }
        h3 {
            color: #182952;
            font-size: 20px;
            line-height: 1.2;
            font-family: 'neue-plak';
            font-weight: 500;
            margin: 0;
            @media (min-width: 1024px) {
                font-size: 24px;
                letter-spacing: .1px;
            }
        }
        p {
            font-size: 18px;
            line-height: 1.5;
            hyphens: auto;
        }
        a {
            margin-top: 65px;
            text-decoration: none;
            color: #f3972b;
            font-size: 18px;
            font-style: italic;
            font-weight: 700;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    &__image-wrapper {
        background-color: #e8edf8;
    }
    &__image {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}
</style>